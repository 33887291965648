<template>
    <div>
        <div class="imain">
            <div class="box">
                <div style="height: 650px;position: relative;width: 350px;margin: 54px 0 0 15px;">
                     <div class="contain">
                        <img src="https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/samecity_forum.jpg" alt="img" class="imagebg">
                        <!-- 轮播图 -->
                        <div :class="['swiper',active=='banner'?'component_checked':'component_canset']" @click="changeActive('banner')">
                            <Swiper :list="bannerinfo" :isShowpart="true"></Swiper>
                        </div>
                    </div>
                    <div class="floatbtn">
                        <div :class="[active=='movable'?'component_checked':'component_canset']" @click="changeActive('movable')">
                            <Movable :movableinfo="movableinfo" :isShowpart="true"></Movable>
                        </div>
                        <img src="https://boweisou.oss-cn-shenzhen.aliyuncs.com/leituo/forum_add.png" alt="img">
                    </div>
                </div>
            </div>
            <Control :active="active">
                <template v-if="active=='banner'">
                    <Swiper :list="bannerinfo" :isShowpart="false" @update-date="updateDate"></Swiper>
                </template>
                 <template v-if="active=='movable'">
                    <Movable :movableinfo="movableinfo" :isShowpart="false" @update-date="updateDate"></Movable>
                </template>
            </Control>
        </div>
        <Preservation @preservation="submit">
        </Preservation>
    </div>
</template>

<script>
import Preservation from '@/components/preservation'
import Swiper from './components/swiper'
import Control from './components/control'
import Movable from './components/movable'

export default {
    components:{
        Preservation,
        Swiper,
        Control,
        Movable
    },
    data:function(){
        return {
            active:'banner',
            bannerinfo:[{
                imgURL: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/bannerDefault.png',
                toPage: '跳转页面',
                choosePagetype:'page'//page--系统页面 webview--第三方链接
            }],
            movableinfo:{
                is_show:'1',
                is_defaultimg:'1',
                imgURL:'https://boweisou.oss-cn-shenzhen.aliyuncs.com/leituo/forum_settled.png'
            },
            hasdata:false
        }
    },
    created(){
        this.getInfo()
    },
    methods:{
        getInfo(){
            this.$axios.post(this.$api.samecity.diyPageList, {
                type:'3'
            })
            .then((res) => {
                if(res.code==0){
                    if(res.result.page_values){
                        let info = res.result.page_values
                        this.bannerinfo = info.banner
                        if(info.movableinfo){
                            this.movableinfo = info.movableinfo
                        }
                        this.hasdata = true
                    }
                }else {
                    this.$message.error(res.msg)
                }
            })
        },
        submit(){
            let info = {
                banner:this.bannerinfo,
                movableinfo:this.movableinfo
            }

            let data = {
                page_name:'论坛首页',
                page_type:'3',
                show_type:'2,3,4,5,6,7',
                page_value:info
            }
            if(this.hasdata){
                data.type = '3'
            }
            this.$axios.post(this.$api.samecity.diyPage, data)
            .then((res) => {
                if(res.code==0){
                    this.$message.success('保存成功')
                    this.getInfo()
                }else {
                    this.$message.error(res.msg)
                }
            })
        },
        updateDate(obj){
            this[obj.attr] = obj.data
        },
        changeActive(active){
            this.active = active
        }
    }
}
</script>

<style lang="less" scoped>
    .imain{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding-top:20px;
        .box{
            width: 384px;
            height:770px;
            background-size: 100% 100%;
            margin-right:20px;
            background-image: url(https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/phonebg.png);
            .contain{
                width: 350px;
                height: 100%;
                overflow: auto;
                position: relative;
                &::-webkit-scrollbar {
                    width: 0px;
                }
                .imagebg{
                    width: 100%;
                    height: auto;
                }
                .swiper{
                    position: absolute;
                    top: 120px;
                    left: 0;
                    right: 0;
                    height: 150px;
                    &::before{
                        content: "";
                        position: absolute;
                        top: -2px;
                        left: 0px;
                        right: 0px;
                        bottom: -2px;
                    }
                }
            }
            .floatbtn{
                position: absolute;
                right: 0;
                top: 365px;
                display: flex;
                flex-direction: column;
                align-items: center;
                :first-child{
                    width: 50px;
                    height: 50px;
                }
                :first-child::before{
                    border-radius: 50%;
                    top: -14px;
                    left: -14px;
                    bottom: -14px;
                    right: -14px;
                }
                :nth-child(2){
                    width: 66px;
                    height: 66px;
                }
            }
        }
    }
    /deep/.el-carousel__indicators{
        .el-carousel__indicator--horizontal{
            padding: 8px 2px;
        }
        .el-carousel__button{
            width: 4px;
            height: 4px;
            border-radius: 2px;
        }
        .is-active button{
            width: 18px;
        }
    }
</style>
