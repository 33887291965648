<template>
  <div :class="['swiperroot', screen ? 'screenBox' : '']">
    <!-- 展示 -->
    <el-carousel class="swiperbox" v-if="isShowpart" :style="{ borderRadius: !screen ? '10px' : '' }" height="100%">
      <el-carousel-item v-for="(item, index) in list" :key="index">
        <img :src="item.imgURL" alt="img" />
      </el-carousel-item>
    </el-carousel>

    <!-- 控制 -->
    <div class="control" v-else>
      <p class="tips">* 建议尺寸694px*300px 上传图片不超过1M，支持png、bmg、jpeg、jpg、gif</p>
      <div class="listItem">
        <vuedraggable
          v-model="list"
          v-bind="{
            animation: 200,
            group: 'description',
            disabled: false,
            ghostClass: 'ghost',
            scroll: true,
          }"
        >
          <transition-group>
            <div class="itemBox" v-for="(item, index) in list" :key="index">
              <div class="left">
                <div class="leftL">
                  <span @click="moveClick(0, index, item)" :class="index == 0 ? 'fcccc' : ''">&lt;</span>
                  <span @click="moveClick(1, index, item)" :class="index == list.length - 1 ? 'fcccc' : ''">></span>
                </div>
                <div class="leftR" @click="chooseImg(index)">
                  <img :src="item.imgURL" alt="img" />
                  <div class="leftR_text">选择图片</div>
                </div>
              </div>
              <div class="right">
                <div style="margin-right: 15px">
                  <div class="right-top">
                    <el-radio-group v-model="item.choosePagetype">
                      <el-radio label="page">系统页面</el-radio>
                      <el-radio label="webview">第三方链接</el-radio>
                    </el-radio-group>
                  </div>
                  <div>
                    <div class="inputBox" v-if="item.choosePagetype == 'page'">
                      <el-input v-model="item.toPage" disabled class="inputstyle"></el-input>
                      <el-button type="primary" @click="choosePage(index)">选择</el-button>
                    </div>
                    <div class="inputBox" v-else>
                      <el-input v-model="item.toPage" placeholder="输入时请携带http或https"></el-input>
                    </div>
                  </div>
                </div>
              </div>
              <div @click="deleteItem(index)" v-if="index > 0" style="cursor: pointer">
                <i class="iconfont icon-guanbi"></i>
              </div>
            </div>
          </transition-group>
        </vuedraggable>
      </div>
      <div @click="addBanner" class="addOne">新增一个</div>
    </div>

    <!-- 选择跳转页面弹框 -->
    <el-dialog title="选择链接" :visible.sync="toPageFlag" width="52%">
      <div>
        <ToPage ref="toPage" @getPage="getPageRes"></ToPage>
      </div>
    </el-dialog>

    <!-- 选择图片弹框 -->
    <UploadSource @changeStatus="changeStatus" :dialogVisible="chooseImgFlag" @getSource="getImgRes" :maxSize="1024 * 1024"></UploadSource>
  </div>
</template>

<script>
import vuedraggable from 'vuedraggable';
import UploadSource from '@/components/uploadSource';
import ToPage from '@/views/addPages/toPage/index';
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    isShowpart: {
      type: Boolean,
      default: true,
    },
    screen: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    vuedraggable,
    UploadSource,
    ToPage,
  },
  data: function () {
    return {
      chooseindex: -1,
      toPageFlag: false,
      chooseImgFlag: false,
    };
  },
  watch: {
    list() {
      this.updateList();
    },
  },
  methods: {
    updateList() {
      this.$emit('update-date', {
        attr: 'bannerinfo',
        data: this.list,
      });
    },
    changeStatus(val) {
      this.chooseImgFlag = val;
    },
    addBanner() {
      if (this.list.length == 5) {
        this.$message({
          message: '最多只可以添加五张banner图哦~',
          type: 'error',
        });
        return;
      }
      this.list.push({
        imgURL: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/bannerDefault.png',
        toPage: '跳转页面',
        choosePagetype: 'page',
      });
    },
    // 点击移动banner图位置
    moveClick(status, index, item) {
      if (status == 0) {
        // 向上移
        if (index == 0) {
          this.$message({
            message: '已经在最前面了~',
            type: 'error',
          });
          return;
        }
        this.list.splice(index, 1);
        this.list.splice(index - 1, 0, item);
      } else {
        // 向下移
        if (index == this.list.length - 1) {
          this.$message({
            message: '已经在最后面了~',
            type: 'error',
          });
          return;
        }
        this.list.splice(index + 2, 0, item);
        this.list.splice(index, 1);
      }
    },
    // 删除对应banner图
    deleteItem(index) {
      this.list.splice(index, 1);
    },
    // 选择跳转路径
    choosePage(index) {
      this.toPageFlag = true;
      this.chooseindex = index;
      this.$nextTick(() => {
        this.$refs.toPage.getPageArr();
      });
    },
    // 拿到跳转路径
    getPageRes(item) {
      this.list[this.chooseindex].toPage = item.wxroute;
      this.toPageFlag = false;
    },
    // 拿到图片
    getImgRes(imglist) {
      this.list[this.chooseindex].imgURL = imglist[0].path;
    },
    // 选择图片
    chooseImg(index) {
      this.chooseImgFlag = true;
      this.chooseindex = index;
    },
  },
};
</script>

<style scoped lang="less">
.swiperroot {
  position: relative;
  height: 100%;
}
.swiperbox {
  width: 324px;
  margin: 0 auto;
  height: 100%;
  .indicator {
    position: absolute;
  }
  img {
    width: 324px;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
  }
}
.fcccc {
  color: #ccc;
  border: 1px solid #ccc !important;
  cursor: no-drop !important;
}
.tips {
  font-size: 14px;
  color: #9a9a9a;
  margin-top: 20px;
}
.listItem {
  width: 100%;
  max-height: 555px;
  overflow: hidden;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 0px;
    height: 5px;
    /**/
  }
  .itemBox {
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 20px;
    .left {
      display: flex;
      margin-right: 15px;
      align-items: center;
      .leftL {
        font-family: SimSun;
        width: 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-right: 10px;

        span {
          width: 16px;
          height: 16px;
          line-height: 16px;
          text-align: center;
          border-radius: 5px;
          display: block;
          border: 1px solid #333;
          font-weight: bold;
          cursor: pointer;
        }
        span:first-child {
          transform: rotate(90deg);
        }
        span:last-child {
          transform: rotate(90deg);
          margin-top: 10px;
        }
      }
      .leftR {
        height: 90px;
        width: 160px;
        overflow: hidden;
        position: relative;
        cursor: pointer;
        img {
          width: 160px;
          height: 90px;
          object-fit: cover;
        }
        .leftR_text {
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          height: 26px;
          line-height: 26px;
          font-size: 12px;
          text-align: center;
          background-color: rgba(0, 0, 0, 0.4);
          color: #e4e4e4;
        }
      }
    }
    .right {
      flex: 1;
      display: flex;
      align-items: center;
      .right-top {
        margin-bottom: 20px;
      }
    }
  }
}
.addOne {
  width: 100%;
  height: 40px;
  text-align: center;
  line-height: 40px;
  color: #3c76ff;
  border: 1px solid #3c76ff;
  cursor: pointer;
  margin-top: 20px;
}
.inputstyle {
  width: 170px;
}
.screenBox {
  .swiperbox {
    width: 100%;
    img {
      width: 100%;
      border-radius: 0px;
    }
  }
}
</style>
