<template>
    <div>
        <div class="imain">
            <div class="box">
                <div style="height: 650px;position: relative;width: 350px;margin: 54px 0 0 15px;">
                     <div class="contain">
                        <img src="https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/samecity_index.jpg" alt="img" class="imagebg">
                        <!-- 轮播图 -->
                        <div :class="['swiper',active=='banner'?'solid':'dotted']" @click="changeActive('banner')">
                            <Swiper :list="bannerinfo" :isShowpart="true"></Swiper>
                        </div>
                        <!-- 按钮组 -->
                         <div :class="['button1',active=='button1'?'solid':'dotted']" @click="changeActive('button1')">
                            <Button :buttoninfo="buttoninfo1" :isShowpart="true" ></Button>
                        </div>
                        <div :class="['button2',active=='button2'?'solid':'dotted']" @click="changeActive('button2')">
                            <Button :buttoninfo="buttoninfo2" :isShowpart="true"></Button>
                        </div>
                    </div>
                    <img src="https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/samecity_tabbar.jpg" alt="img" class="imagetabbar">
                </div>
            </div>
            <Control :active="active">
                <template v-if="active=='banner'">
                    <Swiper :list="bannerinfo" :isShowpart="false" @update-date="updateDate"></Swiper>
                </template>
                 <template v-if="active=='button1'">
                    <Button :buttoninfo="buttoninfo1" :isShowpart="false" @update-date="updateDate" :active="'buttoninfo1'"></Button>
                </template>
                 <template v-if="active=='button2'">
                    <Button :buttoninfo="buttoninfo2" :isShowpart="false" @update-date="updateDate" :active="'buttoninfo2'"></Button>
                </template>
            </Control>
        </div>
        <Preservation @preservation="submit">
        </Preservation>
    </div>
</template>

<script>
import Preservation from '@/components/preservation'
import Swiper from './components/swiper'
import Button from './components/button'
import Control from './components/control'

export default {
    components:{
        Preservation,
        Swiper,
        Control,
        Button
    },
    data:function(){
        return {
            active:'banner',
            bannerinfo:[{
                imgURL: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/bannerDefault.png',
                toPage: '跳转页面',
                choosePagetype:'page'//page--系统页面 webview--第三方链接
            }],
            buttoninfo1:{
                layout:'3',
                list:[
                    {
                        name: '按钮文字',
                        imgUrl: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/zheng.png',
                        toPage: '跳转页面'
                    },{
                        name: '按钮文字',
                        imgUrl: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/zheng.png',
                        toPage: '跳转页面'
                    },{
                        name: '按钮文字',
                        imgUrl: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/zheng.png',
                        toPage: '跳转页面'
                    }
                ],
                bgColor:null
            },
            buttoninfo2:{
                layout:'3',
                list:[
                    {
                        name: '按钮文字',
                        imgUrl: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/zheng.png',
                        toPage: '跳转页面'
                    },{
                        name: '按钮文字',
                        imgUrl: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/zheng.png',
                        toPage: '跳转页面'
                    },{
                        name: '按钮文字',
                        imgUrl: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/zheng.png',
                        toPage: '跳转页面'
                    }
                ],
                bgColor:null
            },
            hasdata:false
        }
    },
    created(){
        this.getInfo()
    },
    methods:{
        getInfo(){
            this.$axios.post(this.$api.samecity.diyPageList, {
                type:'1'
            })
            .then((res) => {
                if(res.code==0){
                     if(res.result.page_values){
                        let info = res.result.page_values
                        this.bannerinfo = info.banner
                        this.buttoninfo1 = info.button1
                        this.buttoninfo2 = info.button2
                        this.hasdata = true
                    }
                }else {
                    this.$message.error(res.msg)
                }
            })
        },
        submit(){
            let info = {
                banner:this.bannerinfo,
                button1:this.buttoninfo1,
                button2:this.buttoninfo2
            }
            let data = {
                page_name:'同城首页',
                page_type:'1',
                show_type:'2,3,4,5,6,7',
                page_value:info
            }
            if(this.hasdata){
                data.type = '1'
            }
            this.$axios.post(this.$api.samecity.diyPage, data)
            .then((res) => {
                if(res.code==0){
                    this.$message.success('保存成功')
                    this.getInfo()
                }else {
                    this.$message.error(res.msg)
                }
            })
        },
        updateDate(obj){
            this[obj.attr] = obj.data
        },
        changeActive(active){
            this.active = active
        }
    }
}
</script>

<style lang="less" scoped>
    .imain{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding-top:20px;
        .box{
            width: 384px;
            height:770px;
            background-size: 100% 100%;
            margin-right:20px;
            background-image: url(https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/phonebg.png);
            .contain{
                width: 350px;
                height: 100%;
                overflow: auto;
                position: relative;
                &::-webkit-scrollbar {
                    width: 0px;
                }
                .imagebg{
                    width: 100%;
                    height: auto;
                }
                .swiper{
                    position: absolute;
                    top: 128px;
                    left: 0;
                    right: 0;
                    height: 140px;
                    &::before{
                        content: "";
                        position: absolute;
                        top: -2px;
                        left: 0px;
                        right: 0px;
                        bottom: -2px;
                    }
                }
                .button1{
                    position: absolute;
                    top: 290px;
                    left: 0;
                    right: 0;
                      &::before{
                        content: "";
                        position: absolute;
                        top: -2px;
                        left: 0px;
                        right: 0px;
                        bottom: -2px;
                    }
                }
                .button2{
                    position: absolute;
                    top: 384px;
                    left: 0;
                    right: 0;
                      &::before{
                        content: "";
                        position: absolute;
                        top: -2px;
                        left: 0px;
                        right: 0px;
                        bottom: -2px;
                    }
                }
                .dotted::before{
                    border: 1px dotted #1467ff;
                }
                .solid::before{
                    border: 2px solid #1467ff;
                }
            }
            .imagetabbar{
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 53px;
            }
        }
    }
    /deep/.el-carousel__indicators{
        .el-carousel__indicator--horizontal{
            padding: 8px 2px;
        }
        .el-carousel__button{
            width: 4px;
            height: 4px;
            border-radius: 2px;
        }
        .is-active button{
            width: 18px;
        }
    }
</style>
